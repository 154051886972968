<template>
  <div class="port">
    <div class="l-col port__config">
      <div v-for="opt in nav" :key="opt.k" class="port__config">
        <base-radio v-model="type" :value="opt.k" theme="tile" class="port__config-item">
          {{ opt.v }}
        </base-radio>
      </div>
    </div>
    <div v-if="type === '1'">
      <div class="l-col">
        <label class="typo__label">{{ $t('label.type') }}</label>
        <base-select
          v-model="formData.port.fixed_ips[0].subnet_id"
          select-label=""
          :hide-selected="true"
          :searchable="false"
          placeholder="Выберите подсеть"
          :custom-label="subnetName"
          :options="subnet"
          size="medium"
          :allow-empty="false"
          class="new-disk__services"
          @input="onChange"
        >
        </base-select>
      </div>
      <div v-if="isNewPort" class="l-col">
        <div v-if="formData.port.fixed_ips[0].subnet_id && value.length > 0">
          <label class="typo__label">{{ $t('port.label') }}</label>
          <!--          :label="$t('port.label')"-->
          <base-input
            v-model="buferIp"
            class="form__field--input"
            :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
            use-reactive-validation
            :pattern="value"
            @input="onChange"
          >
          </base-input>
        </div>
        <div v-if="formData.port.fixed_ips[0].subnet_id && value.length === 0">
          <label class="typo__label error-color medium-title">{{ $t('label.error') }}</label>
        </div>
      </div>
      <div v-else-if="namePort" class="l-col">
        <p class="typo__label" v-html="$t('updatePort', { msg: namePort })"></p>
        <label class="typo__label">{{ $t('addressesPort') }}</label>
        <div class="l-col">
          <div v-for="address in addressesPort" :key="address.ip_address">
            <label class="typo__label l-col">{{ address.ip_address }}</label>
          </div>
        </div>
        <div v-if="formData.port.fixed_ips[0].subnet_id && value.length > 0">
          <label class="typo__label">{{ $t('port.update') }}</label>
          <base-input
            v-model="buferIp"
            class="form__field--input"
            :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
            use-reactive-validation
            :pattern="value"
            @input="onChange"
          >
          </base-input>
        </div>
        <div v-if="formData.port.fixed_ips[0].subnet_id && value.length === 0">
          <label class="typo__label error-color medium-title">{{ $t('label.error') }}</label>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="l-col">
        <label class="typo__label">{{ $t('label.port') }}</label>
        <base-select
          v-model="portId"
          select-label=""
          :hide-selected="true"
          :searchable="false"
          placeholder="Выберите порт"
          :custom-label="allFreePortsName"
          :options="freePortsIds"
          size="medium"
          :allow-empty="false"
          class="new-disk__services"
          @input="onChange"
        >
        </base-select>
      </div>
      <div class="l-col"></div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import BaseRadio from '@/components/Checkboxes/BaseRadio';
export default {
  name: 'NewPortServer',
  components: { BaseInput, BaseSelect, BaseRadio },
  mixins: [setFocus],
  props: {
    instance: {
      type: Array,
      default: () => [],
    },
    port: {
      type: Array,
      default: () => [],
    },
    net: {
      type: String,
      default: '',
    },
    device: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      isNewPort: true,
      addressesPort: [],
      id: '',
      type: '1',
      reserveIp: [],
      namePort: '',
      buferIp: '',
      useIp: [],
      ipUsed: [],
      fullIp: [],
      nav: [
        {
          v: this.$t('nav.base'),
          k: '1',
        },
        {
          v: this.$t('nav.other'),
          k: '2',
        },
      ],
      mask: '',
      listIp: [],
      pullIp: [],
      portId: '',
      newIp: [],
      isShared: false,
      value: '',
      formData: {
        port: {
          network_id: '',
          fixed_ips: [
            {
              subnet_id: '',
              ip_address: '',
              device_id: '',
            },
          ],
        },
      },
    };
  },
  computed: {
    networks() {
      return (
        this.$store.state.moduleStack.networks
          .filter(x => x['router:external'] === false)
          // .filter(x => x.shared === false)
          .map(x => x.id)
      );
    },
    fixetIps() {
      return this.port.filter(x => x.subnetId === this.id).length
        ? this.port.find(x => x.subnetId === this.id).fixed_ips
        : [];
    },
    subnet() {
      const filtredSub = [];
      const sub = this.$store.state.moduleStack.subnets;
      this.networks.forEach(network => {
        filtredSub.push(sub.filter(subnet => subnet.network_id === network));
      });
      return filtredSub.flat().map(x => x.id);
    },
    allocation_pools() {
      return this.id
        ? this.$store.state.moduleStack.subnets.find(x => x.id === this.id).allocation_pools
        : null;
    },
    allFreePorts() {
      return this.$store.state.moduleStack.ports.filter(x => !x.device_owner && !x.device_id);
    },
    freePortsIds() {
      return this.allFreePorts.map(x => x.id);
    },
  },
  watch: {
    type(newVal, oldVal) {
      this.$emit('type', newVal);
      if (newVal !== oldVal) {
        this.formData = {
          port: {
            network_id: '',
            fixed_ips: [
              {
                subnet_id: '',
                ip_address: '',
                device_id: '',
              },
            ],
          },
        };
        this.portId = '';
        this.id = '';
        this.namePort = '';
        this.addressesPort = [];
        this.isNewPort = true;
      }
    },
    id() {
      if (this.id) {
        const net = this.formData.port.network_id;
        this.isShared = this.$store.state.moduleStack.networks.find(x => x.id === net).shared;
        // console.log(this.port.filter(x => x.subnetId === this.id).length);

        this.isNewPort = this.port.filter(x => x.subnetId === this.id).length === 0;
        if (!this.isNewPort) {
          // вытаскиваем имя порта и список подключенных адресов
          const currentPortAddresses = this.port.find(x => x.subnetId === this.id).fixed_ips;
          const port = this.port.find(x => x.subnetId === this.id);
          const portId = port.id;
          this.portId = portId;
          // console.log(portId);
          const findedPort = this.$store.state.moduleStack.ports.find(x => x.id === portId);

          this.namePort = findedPort.name ? findedPort.name : findedPort.id;
          // this.addressesPort = currentPortAddresses.map(x => x.ip_address);
          this.addressesPort = currentPortAddresses;
          // console.log(currentPortAddresses.map(x => x.ip_address));

          // this.addressesPort =
        } else {
          this.portId = '';
          this.namePort = '';
          this.addressesPort = [];
        }
      }

      // console.log(this.id);
    },
    listIp: function (event) {
      this.mask = '';
      this.value = '';
      this.newIp = [];

      if (this.isShared) {
        if (event.length === 0) {
          const aviableIp = [
            this.getLastIpSection(this.allocation_pools[0].start),
            this.getLastIpSection(this.allocation_pools[0].end),
          ];
          this.mask = this.getMask(this.allocation_pools[0].start);
          this.useIp = event.map(x => this.getLastIpSection(x));
          this.reserveIp = this.getLastIpSection(this.allocation_pools[0].end);
          this.pullIp = Array.from(new Set(this.useIp.concat(this.reserveIp))).sort(
            (a, b) => a - b
          );
          aviableIp.forEach((x, i) => {
            if (aviableIp[i + 1] - aviableIp[i] > 1 && i - 1 !== aviableIp.length) {
              this.newIp.push(aviableIp[i + 1]);
            }
          });
          const ip =
            this.listIp.length > 0
              ? this.listIp[0]
              : this.mask + this.getLastIpSection(this.allocation_pools[0].start);
          if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
          this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
        } else {
          this.useIp = event.map(x => this.getLastIpSection(x));
          this.pullIp = [
            this.getLastIpSection(this.allocation_pools[0].start),
            this.getLastIpSection(this.allocation_pools[0].end),
          ];
          this.fullIp = new Array(this.pullIp[1] - this.pullIp[0] + 1)
            .fill()
            .map((_, idx) => this.pullIp[0] + idx);
          this.newIp = this.fullIp.filter(x => !this.useIp.includes(x));
          const ip = this.allocation_pools[0].start;
          this.mask = this.getMask(ip);
          if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
          this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
        }
      } else {
        if (event.length) {
          this.useIp = event.map(x => this.getLastIpSection(x));
          this.reserveIp = this.getLastIpSection(this.allocation_pools[0].end) + 1;
          this.pullIp = Array.from(new Set(this.useIp.concat(this.reserveIp))).sort(
            (a, b) => a - b
          );
          this.pullIp.forEach((x, i) => {
            if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
              this.newIp.push(this.pullIp[i] + 1);
            }
          });
          const ip = this.listIp.length > 0 ? this.listIp[0] : null;
          this.mask = this.getMask(ip);
          if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
          this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
        } else {
          this.pullIp = [
            this.getLastIpSection(this.allocation_pools[0].start),
            this.getLastIpSection(this.allocation_pools[0].end),
          ];
          this.pullIp.forEach((x, i) => {
            if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
              this.newIp.push(this.pullIp[i] + 1);
            }
          });
          const ip = this.allocation_pools[0].start;
          this.mask = this.getMask(ip);
          if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
          this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
          return console.log('список пуст!');
        }
      }
    },

    value: function (event) {
      this.formData.port.fixed_ips[0].ip_address = event;
      const changedDate = {
        port: this.formData.port,
      };
      if (!this.isNewPort) changedDate.fixed_ips = this.fixetIps;
      if (!this.isNewPort) changedDate.portId = this.portId;
      return this.$emit('change', changedDate);
      // return this.$emit('change', {
      //   port: this.formData.port,
      // });
    },
  },
  mounted() {
    this.isReadyNow();
    // this.formData.port.network_id = this.net;
    this.formData.port.fixed_ips[0].device_id = this.device;
    this.allocation_pools;
  },

  beforeDestroy() {
    this.id = '';
    this.portId = '';
  },
  methods: {
    subnetName(id) {
      const netName = this.$store.state.moduleStack.networks.find(x =>
        x.subnets.find(i => i === id)
      ).name;
      let shared = true;
      shared = this.$store.state.moduleStack.networks.find(x =>
        x.subnets.find(i => i === id)
      ).shared;
      const subnetName = this.$store.state.moduleStack.subnets.find(x => x.id === id).cidr;
      return shared ? `${subnetName}` : `${netName} - ${subnetName}`;
    },
    allFreePortsName(id) {
      const netId = this.allFreePorts.find(x => x.id === id).network_id;
      const shared = this.$store.state.moduleStack.networks.find(x => x.id === netId).shared;
      const netName = this.$store.state.moduleStack.networks.find(x => x.id === netId).name;
      const subnetId = this.allFreePorts.find(x => x.id === id).fixed_ips[0].subnet_id;
      const subnetName = this.$store.state.moduleStack.subnets.find(x => x.id === subnetId).name;
      const ip = this.allFreePorts.find(x => x.id === id).fixed_ips[0].ip_address;
      const namePort = this.allFreePorts.find(x => x.id === id).name;
      return namePort ? `${ip} - ${namePort}` : `${ip} - ${shared ? subnetName : netName}`;
    },
    getMask(ip) {
      // console.log(ip);
      return ip ? ip.slice(0, ip.lastIndexOf('.') + 1) : null;
    },
    isCorrectIp(ip) {
      if (ip !== this.value) {
        if (this.getMask(ip) !== this.mask) {
          this.$emit('notready');
        } else if (this.pullIp.includes(this.getLastIpSection(ip))) {
          this.$emit('notready');
          this.formData.port.fixed_ips[0].ip_address = '';
        } else if (isNaN(this.getLastIpSection(ip))) {
          this.formData.port.fixed_ips[0].ip_address = '';
          this.$emit('notready');
        } else if (
          this.getLastIpSection(this.allocation_pools[0].end) < this.getLastIpSection(ip) ||
          this.getLastIpSection(this.allocation_pools[0].start) > this.getLastIpSection(ip)
        ) {
          this.formData.port.fixed_ips[0].ip_address = '';
          this.$emit('notready');
        } else {
          this.value = ip;
          this.formData.port.fixed_ips[0].ip_address = ip;
          this.$emit('ready', {
            port: this.formData.port,
          });
        }
      } else {
        this.formData.port.fixed_ips[0].ip_address = ip;
        this.$emit('ready', {
          port: this.formData.port,
        });
      }
    },
    getLastIpSection(ip) {
      return +ip.split('.').at(-1);
    },
    isReadyNow() {
      if (
        this.formData.port.fixed_ips[0].subnet_id &&
        this.formData.port.fixed_ips[0].ip_address &&
        this.formData.port.network_id
      ) {
        return this.$emit('ready', {
          port: this.formData.port,
        });
      } else {
        return this.$emit('notready');
      }
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr);
    },
    onChange(event) {
      // console.log(event);
      if (this.type === '1') {
        if (this.subnet.includes(event)) {
          this.id = event;
          this.formData.port.network_id = this.$store.state.moduleStack.networks.find(x =>
            x.subnets.find(i => i === this.id)
          ).id;
          const port = this.$store.state.moduleStack.ports;
          const updatePort = port.map(x => x.fixed_ips.filter(a => a.subnet_id === event));
          const formatPort = updatePort.reduce((acc, item) => {
            if (item !== []) acc.push(item);
            return acc;
          }, []);
          // console.log(formatPort.filter(x => x[0]).flat());
          this.listIp = formatPort
            .filter(x => x[0])
            .flat()
            .map(x => x.ip_address)
            // .map(x => x[0].ip_address)
            .sort((a, b) => a.split('.').at(-1) - b.split('.').at(-1));
          // if (this.port.length) {
          //   this.listIp
          // }
        } else {
          Object.assign(this.formData, event);
          // console.log(event); //в этом моменте надо делать проверку адреса введенного, который является event
          this.isCorrectIp(event);
          if (
            this.formData.port.fixed_ips[0].subnet_id &&
            this.formData.port.fixed_ips[0].ip_address &&
            this.formData.port.network_id
          ) {
            const changedDate = {
              port: this.formData.port,
            };
            if (!this.isNewPort) changedDate.fixed_ips = this.fixetIps;
            if (!this.isNewPort) changedDate.portId = this.portId;
            return this.$emit('change', changedDate);
          } else return this.$emit('notready');
        }
      } else {
        // console.log(event);
        this.portId = event;
        return this.$emit('change', event);
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "error": "Свободные порты закончились!",
      "type": "Подсеть:",
      "port": "Порт:",
      "description": "Описание"
    },
    "error": "Не верный формат",
    "updatePort": "Порт <b>%{msg}</b> будет обновлен",
    "addressesPort": "К порту уже подключены адреса:",
    "port": {
      "label": "IP-адрес:",
      "update": "Добавить новый IP-адрес:"

    },
    "nav": {
      "base" : "Создать новый порт",
      "other" : "Добавить существующий"
    },
    "sure": {
      "confirm": "Увеличить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.port {
  text-overflow: unset;
  overflow: visible;

  &__config {
    display: flex;
    //justify-content: space-around;
    justify-content: space-around;

    &-item {
    }

  }

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
